'use strict';

import Swiper from 'swiper/bundle';
var isMobile = !($(window).width() >= 768);
var easytimer = require('easytimer.js');


const swiper = new Swiper('#main-page-slider .swiper-container', {
    autoplay: {
        delay: 8000,
    },
    navigation: {
        nextEl: '#main-page-slider .swiper-button-next',
        prevEl: '#main-page-slider .swiper-button-prev',
    },
    // disableOnInteraction: false,
    // preventClicks: false,
    // preventClicksPropagation: false,
    preloadImages: false,
    // Enable lazy loading
});

$.swipers = new Swiper('.closing-soon-campaigns .swiper-container', {
    direction: 'horizontal',
    slidesPerView: 6,
    spaceBetween: 16,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    freeMode: false,
    preloadImages: false,
    watchSlidesVisibility: true,
    breakpoints: {
        // when window width is <= 768px     
        480: {
            slidesPerView: 3
        },

        1024: {
            slidesPerView: 4
        },

        1100: {
            slidesPerView: 4,
        },

        1200: {
            slidesPerView: 5,
        },

        1300: {
            slidesPerView: 6
        }

    }
    // Enable lazy loading
});

const productSlider = new Swiper('.product-area .swiper-container', {
    direction: 'horizontal',
    slidesPerView: 6,
    spaceBetween: 16,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    freeMode: false,
    preloadImages: false,
    watchSlidesVisibility: true,
    allowTouchMove: false,
    navigation: {
        nextEl: '.product-area .swiper-button-next',
        prevEl: '.product-area .swiper-button-prev',
    },
    breakpoints: {
        // when window width is <= 768px     
        480: {
            slidesPerView: 3
        },
        1024: {
            slidesPerView: 4
        },
        1200: {
            slidesPerView: 6,
        }

    }
    // Enable lazy loading
});

$('body').on('live:winnerLoaded', function (e) {
    const winnerSlider = new Swiper('.winners-campaign-area .swiper-container', {
        direction: 'horizontal',
        spaceBetween: 28,
        slidesPerView: 4,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        freeMode: false,
        preloadImages: false,
        watchSlidesVisibility: true,

        navigation: {
            nextEl: '.winners-campaign-area .swiper-button-next',
            prevEl: '.winners-campaign-area .swiper-button-prev',
        },
        // Enable lazy loading
        breakpoints: {
            // when window width is <= 768px     
            480: {
                slidesPerView: 2
            },
            1024: {
                slidesPerView: 3
            },
            1200: {
                slidesPerView: 4,
            }

        }
    });

    if (!isMobile) {
        var $flag = true;
        winnerSlider.on('slideChange', function () {
            if ($flag) {
                var $this = $('#winnerScroller');
                var url = $this.data('urlnew');
                var pageNo = $this.data('pageno');
                var pageSize = $this.data('pagesize');
                url += '?&pagesize=' + pageSize + '&pageno=' + pageNo;
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        // $('.winners-body').append(data.renderedProducts);
                        winnerSlider.appendSlide(data.renderedProducts);
                        $(document).trigger('reInit');
                        // $this.text((pageSize * pageNo) + ' / ' + data.total);
                        if (data.lastPage || (data.total <= (pageSize * pageNo))) {
                            $flag = false;
                        } else {
                            $this.data('pageno', data.pageNo);
                            $flag = true;
                        }
                    }
                });
            }
        });
    }
});

$('body').on('live:soldoutLoaded', function (e) {
    const soldoutSlider = new Swiper('.soldout-campaign-area .swiper-container', {
        direction: 'horizontal',
        spaceBetween: 28,
        slidesPerView: 4,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        freeMode: false,
        preloadImages: false,
        watchSlidesVisibility: true,

        navigation: {
            nextEl: '.soldout-campaign-area .swiper-button-next',
            prevEl: '.soldout-campaign-area .swiper-button-prev',
        },
        // Enable lazy loading
        breakpoints: {
            // when window width is <= 768px     
            480: {
                slidesPerView: 2
            },
            1024: {
                slidesPerView: 3
            },
            1200: {
                slidesPerView: 4,
            }

        }
    });
});

$('body').on('livecampaign:loaded', function (e) {
    $('.miniSlider').each(function (index) {
        var $this = $(this);
        var $newClass = `newS${index}`;
        var $newClassP = `newS${index}-promotional`;
        var $newClassPP = `associate-products-slider-${index}`;
        var $parent = $this.parent();
        var launched = $parent.find('.js-promotional-message');
        launched.addClass($newClassP);
        $this.addClass($newClass);
    
        const newSlide = new Swiper(`.${$newClass}`, {
            preloadImages: false,
            watchSlidesVisibility: true,
            pagination: {
                el: ".swiper-pagination",
            },
        });
    
        const newSlides = new Swiper(`.${$newClassP}`, {
            preloadImages: false,
            watchSlidesVisibility: true,
            grabCursor: true,
            effect: "fade",
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
        });
    });
    if (!isMobile) {
        $('.js-associate-products-slider').each(function (index) {
            var $this = $(this);

            $this.addClass('asoociate-product-slider-' + index);
        
            const newSlides = new Swiper(`.asoociate-product-slider-${index}`, {
                preloadImages: false,
                slidesPerView: 'auto',
                watchSlidesVisibility: true,
                spaceBetween: 17,
                grabCursor: true,
                fadeEffect: {
                    crossFade: true
                },
                watchOverflow: true,
                navigation: {
                    nextEl: `.asoociate-product-slider-${index} .swiper-button-next`,
                    prevEl: `.asoociate-product-slider-${index} .swiper-button-prev`,
                },
            });

        });
    }
});

const winnerSlider = new Swiper('.products-campaign-area .swiper-container', {
    direction: 'horizontal',
    spaceBetween: 28,
    slidesPerView: 4,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    freeMode: false,
    preloadImages: false,
    watchSlidesVisibility: true,

    navigation: {
        nextEl: '.products-campaign-area .swiper-button-next',
        prevEl: '.products-campaign-area .swiper-button-prev',
    },
    // Enable lazy loading
    breakpoints: {
        // when window width is <= 768px     
        480: {
            slidesPerView: 2
        },
        1024: {
            slidesPerView: 3
        },
        1200: {
            slidesPerView: 4,
        }

    }
});


const padWithZeroes = (num, size) => {
    var s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
};

const newCountdown = (di, second) => {
    var parentDiv = $(di);
    var closedLabel = resources.closedLabel;
    var daysLabel = resources.daysLabel;
    var hourLabel = resources.hourfullLabel;
    var minLabel = resources.minsfullLabel;
    var secdLabel = resources.secdfullLabel;
    var timer = new easytimer.Timer();
    var timeRemaningDiv = parentDiv.find('.timeremaining');

    timer.start({
        countdown: true,
        startValues: {
            seconds: second
        },
        target: { seconds: 3 }
    });

    timer.addEventListener('secondsUpdated', function () {
        var days = (timer.getTimeValues().days).toString();
        var hours = (timer.getTimeValues().hours).toString();
        var minutes = (timer.getTimeValues().minutes).toString();
        var seconds = (timer.getTimeValues().seconds).toString();

        if (days < 2) {
            parentDiv.addClass('hold').removeClass('getready getset')
        } else if (days >= 2 && days < 5) {
            parentDiv.addClass('getset').removeClass('hold getready')
        } else {
            parentDiv.addClass('getready').removeClass('hold getset')
        }
        timeRemaningDiv.html('<div><div>' + padWithZeroes(days, 2) + '<span class="seperator">:</span></div><span class="dayLabel">' + daysLabel + '</span></div><div><div>' + padWithZeroes(hours, 2) + '<span class="seperator">:</span></div><span class="dayLabel">' + hourLabel + '</span></div><div><div>' + padWithZeroes(minutes, 2) + '<span class="seperator">:</span></div><span class="dayLabel">' + minLabel + '</span></div><div><div>' + padWithZeroes(seconds, 2) + '</div><span class="dayLabel">' + secdLabel + '</span></div>');

    });

    timer.addEventListener('targetAchieved', function () {
        var findeWinner = parentDiv.parent().find('.winnerSwitch.hide');
        var winnerEach = parentDiv.parent().find('.noWinnerAnnounce');
        if (findeWinner.length > 0) {
            parentDiv.hide()
            findeWinner.removeClass('hide');

            var winnerTime = findeWinner.data('winnertime');

            var second = new Date(winnerTime).getTime();
            var todaysSecond = new Date().getTime();

            var milli = (winnerTime * 1000) - todaysSecond;

            // setTimeout(function () {
            //     findeWinner.find('.countDowns.first').removeClass('show');
            //     findeWinner.find('.countDowns.second').addClass('show');
            // }, 1000);

            // setTimeout(function () {
            //     findeWinner.find('.countDowns.second').removeClass('show');
            //     findeWinner.find('.countDowns.third').addClass('show');
            // }, 2000)

            setTimeout(function () {
                findeWinner.find('.countDowns.third').removeClass('show');
                findeWinner.find('.countDowns.fourth').addClass('show');
            }, 1000)

            setTimeout(function () {
                findeWinner.find('.countDowns.fourth').removeClass('show');
                findeWinner.find('.countDowns.fifth').addClass('show');
            }, 2000)

            setTimeout(function () {
                findeWinner.find('.countDowns.fifth').removeClass('show');
                findeWinner.find('.holdTight').addClass('show');
            }, 3000)

            setTimeout(function () {
                findeWinner.removeClass('hold');
                findeWinner.parent().find('.draw-campagin-box').addClass('winnerAnnounced');
            }, 5000)
        }
        if (winnerEach.length > 0) {
            parentDiv.hide()
            winnerEach.removeClass('hide');

            var winnerTime = winnerEach.data('winnertime');

            var second = new Date(winnerTime).getTime();
            var todaysSecond = new Date().getTime();

            var milli = winnerTime;

            // setTimeout(function () {
            //     winnerEach.find('.countDowns.first').removeClass('show');
            //     winnerEach.find('.countDowns.second').addClass('show');
            // }, 1000);

            // setTimeout(function () {
            //     winnerEach.find('.countDowns.second').removeClass('show');
            //     winnerEach.find('.countDowns.third').addClass('show');
            // }, 2000)

            setTimeout(function () {
                winnerEach.find('.countDowns.third').removeClass('show');
                winnerEach.find('.countDowns.fourth').addClass('show');
            }, 1000)

            setTimeout(function () {
                winnerEach.find('.countDowns.fourth').removeClass('show');
                winnerEach.find('.countDowns.fifth').addClass('show');
            }, 2000)

            setTimeout(function () {
                winnerEach.find('.countDowns.fifth').removeClass('show');
                winnerEach.find('.holdTight').addClass('show');
            }, 3000)

            // setTimeout(function () {
            //     winnerEach.removeClass('hold');
            //     // winnerEach.parent().find('.draw-campagin-box').addClass('winnerAnnounced');
            // }, milli + 2000)
        }
    });

};

$('body').on('live:drawloaded', function (e) {
    const soldoutSlider = new Swiper('.draw-campaign-area .swiper-container', {
        direction: 'horizontal',
        spaceBetween: 28,
        slidesPerView: 4,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        freeMode: false,
        preloadImages: false,
        watchSlidesVisibility: true,

        navigation: {
            nextEl: '.draw-campaign-area .swiper-button-next',
            prevEl: '.draw-campaign-area .swiper-button-prev',
        },
        // Enable lazy loading
        breakpoints: {
            // when window width is <= 768px     
            480: {
                slidesPerView: 2
            },
            1024: {
                slidesPerView: 3
            },
            1200: {
                slidesPerView: 4,
            }

        }
    });

    $('.timerOn').each(function () {
        var $this = $(this);
        var drawTime = $this.data('drawtime');
        var second = new Date(drawTime).getTime() / 1000;
        var todaysSecond = new Date().getTime() / 1000;

        newCountdown($this, drawTime)
    })

    $('.countDownModule').each(function () {
        var $this = $(this);
        setTimeout(function () {
            $this.find('.countDowns.first').removeClass('show');
            $this.find('.countDowns.second').addClass('show');
        }, 1000);

        setTimeout(function () {
            $this.find('.countDowns.second').removeClass('show');
            $this.find('.countDowns.third').addClass('show');
        }, 2000)

        setTimeout(function () {
            $this.find('.countDowns.third').removeClass('show');
            $this.find('.holdTight').addClass('show');
        }, 3000)
    });

    $('.winnerSwitch:not(.noAnim):not(.hide)').each(function () {
        var $this = $(this);
        var winnerTime = $this.data('winnertime');

        var second = new Date(winnerTime).getTime();
        var todaysSecond = new Date().getTime();

        var milli = (winnerTime * 1000) - todaysSecond;

        setTimeout(function () {
            $this.find('.countDowns.first').removeClass('show');
            $this.find('.countDowns.second').addClass('show');
        }, 1000);

        setTimeout(function () {
            $this.find('.countDowns.second').removeClass('show');
            $this.find('.countDowns.third').addClass('show');
        }, 2000)

        setTimeout(function () {
            $this.find('.countDowns.third').removeClass('show');
            $this.find('.holdTight').addClass('show');
        }, 3000)

        // setTimeout(function () {
        //     $this.find('.countDowns.fourth').removeClass('show');
        //     $this.find('.countDowns.fifth').addClass('show');
        // }, 4000)

        // setTimeout(function () {
        //     $this.find('.countDowns.fifth').removeClass('show');
        //     $this.find('.holdTight').addClass('show');
        // }, 5000)

        setTimeout(function () {
            $this.removeClass('hold');
            $this.parent().find('.draw-campagin-box').addClass('winnerAnnounced');
        }, 5000)
    })

    $('.dedTabSwitch a').on('click', function () {
        var $this = $(this);
        var $data = $this.data('draw');
        var $parent = $this.closest('.draw-section');
        $this.addClass('active');
        $this.siblings().removeClass('active');
        $parent.find('.swiper-slide').removeClass('active');
        $parent.find('.swiper-slide.' + $data).addClass('active');
        $parent.find('.emptyDedScreen').removeClass('active');
        $parent.find('.emptyDedScreen.' + $data).addClass('active');
        soldoutSlider.update();
    });
});

$('body').on('quickView:init', function (e) {
    $('.miniSlider').each(function (index) {
        var $this = $(this);
        var $newClass = `newS${index}`;
        var $newClassP = `newS${index}-promotional`;
        var $parent = $this.parent();
        var launched = $parent.find('.js-promotional-message');
        launched.addClass($newClassP);
        $this.addClass($newClass);

        const newSlide = new Swiper(`.${$newClass}`, {
            preloadImages: false,
            watchSlidesVisibility: true,
            pagination: {
                el: ".swiper-pagination",
            },
        });

        const newSlides = new Swiper(`.${$newClassP}`, {
            preloadImages: false,
            watchSlidesVisibility: true,
            grabCursor: true,
            effect: "fade",
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
        });
    })
});