// 'use_strict';
// const appboy = require("@braze/web-sdk");
// var isMobile = !($(window).width() >= 768);

// var initOnAjax = function (externalID) {
//     appboy.initialize(window.brazeKey, {
//         baseUrl: "sdk.fra-01.braze.eu"
//     });

//     // optionally show all in-app messages without custom handling
//     appboy.display.automaticallyShowNewInAppMessages();

//     appboy.changeUser(externalID);
//     appboy.openSession();

//     if (appboy.isPushSupported()) {
//         appboy.registerAppboyPushMessages();
//     }
// }

// module.exports = {
//     initOnLoad: function () {
//         appboy.initialize(window.brazeKey, {
//             baseUrl: "sdk.fra-01.braze.eu"
//         });

//         // optionally show all in-app messages without custom handling
//         appboy.display.automaticallyShowNewInAppMessages();

//         if (isLoggedIn === 'true') {
//             appboy.changeUser(window.localStorage.getItem('external_id'));
//             appboy.openSession();
//             var currentUser = appboy.getUser();
//             if (typeof enocCodes !== 'undefined' && enocCodes !== '') {
//                 currentUser.setCustomUserAttribute('enocCodes', JSON.parse(enocCodes.replaceAll('&quot;', '"')));
//             }
//         }
//         // registerAppboyPushMessages
//         if (appboy.isPushSupported()) {
//             appboy.registerAppboyPushMessages();
//         }
//     },
//     registerUserOnBraze: function () {
//         if (typeof isUserRegister !== 'undefined' && isUserRegister === 'true') {
//             var event = {};
//             var currentUser = appboy.getUser();
//             currentUser.setFirstName(first_name);
//             currentUser.setLastName(last_name);
//             // event.name = first_name + ' '  + last_name;
//             event.customer_id = window.localStorage.getItem('external_id');
//             if (dob !== '') {
//                 var bday  = new Date(dob);
//                 currentUser.setDateOfBirth(bday.getFullYear(), bday.getMonth(), bday.getDate());
//             }
//             currentUser.setEmailNotificationSubscriptionType('opted_in');
//             currentUser.setPushNotificationSubscriptionType('opted_in');
//             var genderVal = (gender === '1') ? 'm' : (gender === '2') ? 'f' : null;
//             if (genderVal !== null) {
//                 currentUser.setGender(genderVal);
//             }
//             currentUser.setPhoneNumber(phone);
//             if (country !== '') {
//                 // event.residency = country;
//                 currentUser.setCountry(country);
//             }
//             if (email !== '') {
//                 currentUser.setEmail(email);
//                 event.email = email;
//             }
//             if (city !== '') {
//                 currentUser.setHomeCity(city);
//             }

//             if (typeof currency !== 'undefined' && currency !== '') {
//                 currentUser.setCustomUserAttribute('currency', currency);
//             }

//             if (typeof ipoint_values !== 'undefined' && ipoint_values !== '0' && ipoint_values !== '0.0') {
//                 currentUser.setCustomUserAttribute('ipoints', parseFloat(ipoint_values));
//             }

//             if (typeof enocCodes !== 'undefined' && enocCodes !== '') {
//                 currentUser.setCustomUserAttribute('enocCodes', JSON.parse(enocCodes.replaceAll('&quot;', '"')));
//             }

//             currentUser.setCustomUserAttribute('user_origin', 'Idealz');

//             // event.registration_source = isMobile ? 'mobile-web' : 'desktop';

//             appboy.logCustomEvent('registration_success', event);
//         }
//     },
//     updateBrazeOnProfileUpdate: function () {
//         if ((typeof isProfileUpdated && isProfileUpdated === 'true') && (typeof profileUpdateData !== 'undefined' && profileUpdateData !== null)) {
//             var currentUser = appboy.getUser();
//             var profileUpdateEvent = {};
//             var profileUpdateDataObj = JSON.parse(profileUpdateData.replace(/&quot;/g,'"'));
//             $.each( profileUpdateDataObj, function( key, value ) {
//                 if (key === 'bday') {
//                     var bday = value.split('-');
//                     if (bday.length > 2) {
//                         currentUser.setDateOfBirth(bday[0], bday[1], bday[2]);
//                         profileUpdateEvent.birthday = bday[0] + '-' + bday[1] + '-' + bday[2];
//                     }
//                 }
//                 if (key === 'firstName') {
//                     currentUser.setFirstName(value);
//                     if (typeof window.fullName !== 'undefined' && window.fullName) {
//                         profileUpdateEvent.name = window.fullName;
//                     }
//                 }
//                 if (key === 'lastName') {
//                     currentUser.setLastName(value);
//                     if (typeof window.fullName !== 'undefined' && window.fullName) {
//                         profileUpdateEvent.name = window.fullName;
//                     }
//                 }
//                 if (key === 'phone') {
//                     currentUser.setPhoneNumber(value);
//                     profileUpdateEvent.phone = value;
//                 }
//                 if (key === 'gender') {
//                     var genderVal = (value === '1') ? 'm' : (value === '2') ? 'f' : null;
//                     currentUser.setGender(genderVal);
//                 }
//                 if (key === 'country') {
//                     currentUser.setCountry(value);
//                     profileUpdateEvent.nationality = value;
//                 }
//             });

//             appboy.logCustomEvent('profile_update', profileUpdateEvent);
//         }
//     },
//     updateBrazeOnMiniPoupUpdate: function (updatedData) {
//         if (typeof updatedData !== 'undefined' && updatedData && updatedData.updateToBraze && (typeof window.localStorage.getItem('external_id') !== 'undefined' && window.localStorage.getItem('external_id') !== '')) {
//             initOnAjax(window.localStorage.getItem('external_id'));
//             var currentUser = appboy.getUser();
//             var event = {};
//             if ('birthday' in updatedData && updatedData.birthday !== null) {
//                 var birthday = updatedData.birthday;
//                 if (birthday.length > 2) {
//                     currentUser.setDateOfBirth(birthday[0], birthday[1], birthday[2]);
//                     event.birthday = birthday[0] + '-' + birthday[1] + '-' + birthday[2];
//                 }
//             }

//             if ('nationality' in updatedData && updatedData.nationality !== '') {
//                 currentUser.setCountry(updatedData.nationality);
//                 event.nationality = updatedData.nationality;
//             }

//             if ('gender' in updatedData && updatedData.gender !== '') {
//                 genderVal = (updatedData.gender === '1') ? 'm' : (updatedData.gender === '2') ? 'f' : null;
//                 currentUser.setGender(genderVal);
//                 event.gender = genderVal;
//             }

//             appboy.logCustomEvent('profile_update', event);

//         }
//     },
//     registerUserOnBrazeMiniPopup: function (data, isMobile) {
//         if (typeof data !== 'undefined' && data && data.success && 'brazeData' in data && (typeof data.brazeData.externalID !== 'undefined' && data.brazeData.externalID !== '')) {
//             initOnAjax(data.brazeData.externalID);
//             var currentUser = appboy.getUser();
//             var event = {};
//             event.customer_id = data.brazeData.externalID;

//             $.each( data.brazeData, function( key, value ) {
//                 if (key === 'firstName') {
//                     currentUser.setFirstName(value);
//                     // event.name = value;
//                 }
//                 if (key === 'lastName') {
//                     currentUser.setLastName(value);
//                     // event.name += ' ' + value;
//                 }
//                 if (key === 'phone') {
//                     currentUser.setPhoneNumber(value);

//                 }
//                 if (key === 'email') {
//                     currentUser.setEmail(value);
//                     event.email = value;
//                 }
//                 if (key === 'city') {
//                     currentUser.setHomeCity(value);
//                 }
//                 if (key === 'currency') {
//                     currentUser.setCustomUserAttribute('currency', value);
//                 }
//                 if (key === 'availableIpoints') {
//                     currentUser.setCustomUserAttribute('ipoints', value);
//                 }
//             });

//             // if (typeof isMobile !== 'undefined' && isMobile) {
//             //     event.registration_source = isMobile ? 'mobile-web' :  'desktop';
//             // }

//             appboy.logCustomEvent('registration_success', event);
//         }
//     },
//     handleAddRemoveEvent: function (cartData) {
//         if ((typeof cartData !== 'undefined' && cartData !== '') && (typeof window.localStorage.getItem('external_id') !== 'undefined' && window.localStorage.getItem('external_id') !== '')) {
//             initOnAjax(window.localStorage.getItem('external_id'));
//             var params = JSON.parse(cartData.param).items;
//             if (params.length > 1) {
//                 for (var g = 0; g < params.length; g++) {
//                     appboy.logCustomEvent(cartData.event, {
//                         campaign_id: params[g].id,
//                         date: new Date().toISOString(),
//                         campaign_name: params[g].name,
//                         product_name: params[g].id,
//                         quantity: 1,
//                         value: params[g].price
//                     });
//                 }
//             } else {
//                 appboy.logCustomEvent(cartData.event, {
//                     campaign_id: params[0].id,
//                     date: new Date().toISOString(),
//                     campaign_name: params[0].name,
//                     product_name: params[0].id,
//                     quantity: 1,
//                     value: params[0].price
//                 });
//             }
//         }
//     },
//     handleLogOutEvent: function () {
//         $('body').on('click', '.logout-btn, .logoutIcon', function () {
//             appboy.logCustomEvent('logout', {
//                 customer_id: window.localStorage.getItem('external_id'),
//                 date: new Date().toISOString()
//             });
//     });
//     },
//     sendCouponApplyEvent: function (couponCode, ordervalue) {
//         if ((typeof couponCode !== 'undefined' && couponCode !== '') && (typeof window.localStorage.getItem('external_id') !== 'undefined' && window.localStorage.getItem('external_id') !== '')) {
//             initOnAjax(window.localStorage.getItem('external_id'));
//             appboy.logCustomEvent('Promo_code_applied', {
//                 Coupon_code: couponCode,
//                 Code_appplied: true,
//                 Order_value: ordervalue
//             });
//         }
//     },
//     initPdpEvents: function () {
//         var pageType = $('.page').data('action');
//         if ((typeof pageType !== 'undefined' && pageType === 'Product-Show') && (typeof window.localStorage.getItem('external_id') !== 'undefined' && window.localStorage.getItem('external_id') !== '')) {
//             initOnAjax(window.localStorage.getItem('external_id'));
//             try {
//                 var pdpViewData = $('span[data-gtag-event]');
//                 if (pdpViewData.length > 0) {
//                     var data = $('span[data-gtag-event]').data('gtag-param');
//                     var items = data.items[0];
//                     appboy.logCustomEvent('Product_page_viewed', {
//                         campaign_id: items.id,
//                         'Campaign ID': items.id,
//                         'Campaign Name': items.name,
//                         Currency: items.currencyCode
//                     });
//                 }
//             } catch (e) {
//                 console.warn('pdp event can not be sent');
//             }

//         }
//     },
//     initSiteSwitchers: function (siteID) {
//         if ((typeof siteID !== 'undefined' && siteID !== '') && (typeof window.localStorage.getItem('external_id') !== 'undefined' && window.localStorage.getItem('external_id') !== '')) {
//             initOnAjax(window.localStorage.getItem('external_id'));
//             appboy.logCustomEvent('Switched_to_another_website', {
//                 site_id: siteID
//             });
//         }
//     },
//     registerPartnerUserOnBraze: function (data) {
//         if (typeof data !== 'undefined' && 'external_id' in  data) {
//             initOnAjax(data.external_id);
//             var event = {};
//             var currentUser = appboy.getUser();
//             currentUser.setFirstName(data.first_name);
//             currentUser.setLastName(data.last_name);
//             event.customer_id = data.external_id;
//             currentUser.setEmailNotificationSubscriptionType('opted_in');
//             currentUser.setPushNotificationSubscriptionType('opted_in');
//             currentUser.setPhoneNumber(data.phone);
//             if (data.email !== '') {
//                 currentUser.setEmail(data.email);
//                 event.email = email;
//             }

//             if (typeof data.currency !== 'undefined' && data.currency !== '') {
//                 currentUser.setCustomUserAttribute('currency', currency);
//             }

//             currentUser.setCustomUserAttribute('user_origin', data.user_origin);

//             appboy.logCustomEvent('registration_success', event);
//         }
//     }
// }
