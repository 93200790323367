var $mobilewidth = $(window).width();
module.exports = {
    getUrlParameter: function (sParam) {
        var sPageURL = decodeURIComponent(window.location.search.substring(1));
        var sURLVariables = sPageURL.split('&');
        var sParameterName;
        var i;
        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    },
    init: function () {
        $('.notLinkWinner').click(function (e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $('#winnerScroller').offset().top - 120
            }, 500);
        });

        $('.notLinkProduct').click(function (e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $('#productScroller').offset().top - 120
            }, 500);
        });
        var param = this.getUrlParameter('campaign');
        var paramRegister = this.getUrlParameter('register');
        var paramInvitationCode = this.getUrlParameter('invitationcode');

        if ((param === 'winnerScroller' || param === 'productScroller' || param === 'drawScroller') && param.length) {
            $.spinner().start();
            if (param === 'winnerScroller') {
                $(document).on('live:initDial', function () {
                    $.spinner().stop();
                    setTimeout(function() {
                        $('html, body').animate({
                            scrollTop: $('#' + param).offset().top + 220
                        }, 500);
                    }, 1000)
                });
            } else if (param === 'drawScroller') {
                $(document).on('live:initDial', function () {
                    $.spinner().stop();
                    $('html, body').animate({
                        scrollTop: $('#' + param).offset().top - 120
                    }, 500);
                });
            } else {
                $(document).on('live:initDial', function () {
                    $.spinner().stop();
                    $('html, body').animate({
                        scrollTop: $('#' + param).offset().top - 120
                    }, 500);
                });
            }
        }

        if (paramRegister === 'true') {
            if ($mobilewidth < 768) {
                $('.login-box').hide();
                $('.register-box').fadeIn();
            } else {
                $(window).load(function () {
                    $('.registerBox').trigger('click');
                });
            }
        }

        if (paramInvitationCode != undefined && paramInvitationCode.length) {
            if ($mobilewidth < 768) {
                $('.login-box').hide();
                $('.register-box').fadeIn();
            } else {
                $('.registerBox').trigger('click');
            }

            $('#registration-form-invitation-code').val(paramInvitationCode);
            $('#registration-form-invitation-code').parent().find('label').addClass('active');
        }

        $('.notLinkWinner').click(function (e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $('#winners-scroll').offset().top - 60
            }, 2000);
        });
    }
};
