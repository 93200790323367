/* eslint-disable valid-jsdoc */
'use strict';
var isMobile = !($(window).width() >= 768);
var braze = require('../braze');
module.exports = {
    handleDetailsForm: function () {
        $('#detailsEdit').on('submit', function (e) {
            e.preventDefault();
            $.ajax({
                url: $(this).attr('action'),
                type: 'POST',
                data: $(this).serialize(),
                success: function (data) {
                    if (isMobile) {
                        $('.profilePopout-Question').fadeOut();
                        $('body').removeClass('no-scroll');
                    } else {
                        $('#profileViewBox').fadeOut();
                        $('#profileViewBox').remove();
                    }

                    // trigger braze events for the following data
                    braze.updateBrazeOnMiniPoupUpdate(data);
                }
            });
        });
    }
};
