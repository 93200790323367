module.exports = {
    setCookie: function (cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    },
    getCookie: function (cname) {
        var name = cname + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    },
    init: function () {
        $(document).ready(function () {
            // if any active poll is there show it if user haven't responded to it
            if ($('.js-active-polls').length > 0) {
                // dispaly poll if user has not submitted it yet
                var activePoillId = $('.js-active-polls').attr('data-poll-id');
                if (activePoillId) {
                    var pollIdSubmitted = module.exports.getCookie('pollSubmited');
                    if (pollIdSubmitted === '' || pollIdSubmitted === 'undefined' || pollIdSubmitted !== activePoillId) {
                        $('.js-active-polls').removeClass('css-dnone');
                    }
                }
                $('.js-active-poll-option').on('click', function (event) {
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    var optionSelected = $(this).attr('data-poll-option');
                    $('.js-selected-option').val(optionSelected);
                    var formDataPolls = $(this).parents('#pollSubmit').serialize();
                    var submitUrl = $(this).parents('#pollSubmit').attr('action');
                    var pollId = $('.js-active-polls').attr('data-poll-id');
                    if (formDataPolls) {
                        $.ajax({
                            url: submitUrl,
                            method: 'POST',
                            data: formDataPolls,
                            success: function (res) {
                                const data = res.response;
                                var first;
                                var second;
                                module.exports.setCookie('pollSubmited', pollId, 365);

                                data.map((item, index) => {
                                    if (index === 0) {
                                        first = item.selectionPercentage;
                                    } else {
                                        second = item.selectionPercentage;
                                    }
                                });

                                const colorFirst = first >= second ? 'green' : 'red';
                                const colorSecond = second >= first ? 'green' : 'red';

                                $('.pollAnswer').find('li:nth-child(1) .pollIndicator').css({ 'width': first + '%' }).addClass(colorFirst);
                                $('.pollAnswer').find('li:nth-child(2) .pollIndicator').css({ 'width': second + '%' }).addClass(colorSecond);

                                $('.pollAnswer').find('li:nth-child(1) .pollPercentage').html(first + '%');
                                $('.pollAnswer').find('li:nth-child(2) .pollPercentage').html(second + '%');

                                $('.pollQuestion').addClass('hide');
                                $('.pollAnswer').addClass('show');
                            },
                            error: function () {

                            }
                        });
                    }
                });

                var counter = 5;
                var interval = setInterval(function () {
                    counter--;
                    // Display 'counter' wherever you want to display it.
                    $('.js-active-polls .skipCount').html(counter);
                    if (counter == 1) {
                        // Display a login box
                        clearInterval(interval);
                    }
                }, 1000);

                setTimeout(() => {
                    $('.js-active-polls .skipProgress').hide();
                    $('.js-active-polls .user-action').show();
                    $('.js-active-polls .close').show();
                }, 6000)

                $('.pollViewBox .close').on("click", function (e) {
                    var pollId = $('.js-active-polls').attr('data-poll-id');
                    $('.pollViewBox').hide();
                    module.exports.setCookie('pollSubmited', pollId, 365);
                });
            }
        });
    }
};
