// @ts-nocheck
'use strict';
var errorLabel = require('./errorLabels');
var processInclude = require('base/util');
var now = new Date();
var $year = now.getFullYear() - 18;
var $month = '' + now.getMonth();
var $date = '' + now.getDate();
var isMobile = !($(window).width() >= 768);

if ($month.length < 2) {
    $month = '0' + $month;
}
if ($date.length < 2) {
    $date = '0' + $date;
}
var eighteenYears = [$year, $month, $date].join('-');

var popupDiv = (divToPop) => {
    var pos = $(this).offset();
    var h = $(this).height();
    var w = $(this).width();

    $(divToPop).css({ left: pos.left + w + 10, top: pos.top + h + 10, });

    $(this).click(function (e) {
        $(divToPop).css({ left: pos.left + w + 10, top: pos.top + h + 10, });
        if ($(divToPop).css('display') !== 'none') {
            $(divToPop).hide();
        }
        else {
            $(divToPop).show();
        }
    });
};

$(document).ready(function () {
    processInclude(require('./components/bannerCarousel'));
    processInclude(require('./components/campaignScroll'));
    processInclude(require('./components/updateCampaignsAjax'));
    processInclude(require('./components/polls'));
    processInclude(require('./components/radioButtons'));
    processInclude(require('./home/detailsPopup'));
    processInclude(require('./components/jquery.datetextentry'));

    if (isMobile) {
        processInclude(require('./components/customSelect'));
    }

    if ($('.profilePopout-Question').length) {
        $('#profileViewBox .close').click(function () {
            $('#profileViewBox').fadeOut();
        });

        $('#birthday').datetextentry({
            'field_width_day': 6,
            'field_width_month': 6,
            'field_width_year': 45,
            'tooltip_y': -11,
            'show_errors': false,
            'show_tooltips': false,
            'max_date': eighteenYears,
            'max_date_message': resources.E_MAX_DATE,
            'on_error': function (msg) {
                if (msg !== '') {
                    $('label.date-label').addClass('invalid').text(msg);
                    $('label.date-label').closest('.input-field').addClass('in-valid');
                    $('form#detailsEdit button.global-btn').addClass('is-disabled');
                } else {
                    var datafiled = $('label.date-label').data('label');
                    $('label.date-label').removeClass('invalid').text(datafiled);
                    $('label.date-label').closest('.input-field').removeClass('in-valid');

                    if ($('#nationality').length) {
                        if ($('#nationality').val() !== '' && $('.jq-dte input.valid').length === 3) {
                            if ($('#genderField').length) {
                                if ($('#genderField').val() !== '') {
                                    $('form#detailsEdit button.global-btn').removeClass('is-disabled');
                                } else {
                                    $('form#detailsEdit button.global-btn').addClass('is-disabled');
                                }
                            } else {
                                $('form#detailsEdit button.global-btn').removeClass('is-disabled');
                            }
                        } else {
                            $('form#detailsEdit button.global-btn').addClass('is-disabled');
                        }
                    } else if ($('#genderField').length) {
                        if ($('#genderField').val() !== '' && $('.jq-dte input.valid').length === 3) {
                            if ($('#nationality').length) {
                                if ($('#nationality').val() !== '') {
                                    $('form#detailsEdit button.global-btn').removeClass('is-disabled');
                                } else {
                                    $('form#detailsEdit button.global-btn').addClass('is-disabled');
                                }
                            } else {
                                $('form#detailsEdit button.global-btn').removeClass('is-disabled');
                            }
                        } else {
                            $('form#detailsEdit button.global-btn').addClass('is-disabled');
                        }
                    } else {
                        $('form#detailsEdit button.global-btn').removeClass('is-disabled');
                    }
                }
            },
            'E_BAD_DAY_FOR_MONTH': resources.E_BAD_DAY_FOR_MONTH,
            'E_DAY_NAN': resources.E_DAY_NAN,
            'E_DAY_TOO_BIG': resources.E_DAY_TOO_BIG,
            'E_DAY_TOO_SMALL': resources.E_DAY_TOO_SMALL,
            'E_MAX_DATE': resources.E_MAX_DATE,
            'E_MIN_DATE': resources.E_MIN_DATE,
            'E_MONTH_NAN': resources.E_MONTH_NAN,
            'E_MONTH_TOO_BIG': resources.E_MONTH_TOO_BIG,
            'E_MONTH_TOO_SMALL': resources.E_MONTH_TOO_SMALL,
            'E_REQUIRED_FIELD': resources.E_REQUIRED_FIELD,
            'E_YEAR_LENGTH': resources.E_YEAR_LENGTH,
            'E_YEAR_NAN': resources.E_YEAR_NAN,
            'E_YEAR_TOO_BIG': resources.E_YEAR_TOO_BIG,
            'E_YEAR_TOO_SMALL': resources.E_YEAR_TOO_SMALL
        });

        if ($('#nationality').length > 0) {
            $('#nationality').select2({
                placeholder: resources.pleaseSelect,
                language: {
                    noResults: function (params) {
                        return resources.labelNoCountry;
                    }
                }
            });
    
            $('#nationality').one('select2:open', function (e) {
                $('input.select2-search__field').prop('placeholder', resources.labelSearch);
            });
        }

        // $(document).ready(function () {
        //     $('.jq-dte-inner input').keypress(function (e) {
        //         var regex = new RegExp("^[0-9-]+$");
        //         var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        //         if (regex.test(str)) {
        //             return true;
        //         }

        //         e.preventDefault();
        //         return false;
        //     });

        //     $('#nationality').select2({
        //         placeholder: resources.pleaseSelect,
        //         language: {
        //             noResults: function (params) {
        //                 return resources.labelNoCountry;
        //             }
        //         }
        //     });

        //     $('#nationality').one('select2:open', function (e) {
        //         $('input.select2-search__field').prop('placeholder', resources.labelSearch);
        //     });
        // });

        // $('form#detailsEdit').validate();

        // $('#birthday').rules('add', {
        //     required: true,
        //     messages: {
        //         required: resources.Global$checkInput
        //     }
        // });

        // $('#genderField').rules('add', {
        //     required: true
        // });
        $('form#emailMissingForm').validate();
        $('#emailMissingForm #registration-form-email').rules('add', {
            required: true,
            email: false,
            regex: errorLabel.email$regex,
            messages: {
                required: resources.Global$emailRequired,
                regex: resources.Global$invalidEmail
            }
        });

        $('form#emailMissingForm').on('submit', function (e) {
            if ($('form#emailMissingForm').valid()) {
                e.preventDefault();
                var data = $(this).serialize();
                $.ajax({
                    type: 'POST',
                    url: $(this).attr('action'),
                    data: data,
                    success: function (response) {
                        if (response.success) {
                            window.location.reload();
                        } else {
                            $('form#emailMissingForm').find('.input-error').text(response.errorMessage).removeClass('hide');
                        }
                    }
                });
            }
        });

        // handling missinh info popup close, if user closes it then do not show during current session
        $('body').on('click', '.js-cancel-missing-info-popup', function (e) {
            var url = $(this).data('href');
            $.ajax({
                type: 'GET',
                url: url,
                success: function () {
                }
            });

        });

        // $('form#detailsEdit').find('select').on('change', function () {
        //     $('#nationality').rules('add', {
        //         required: function (element) {
        //             if ($(element).val() === '') {
        //                 return $(element).closest('.input-field').addClass('in-valid');
        //                 // eslint-disable-next-line no-else-return
        //             } else {
        //                 return $(element).closest('.input-field').removeClass('in-valid');
        //             }
        //         },
        //         messages: {
        //             required: resources.Global$pleaseSelect
        //         }
        //     });

        //     if ($('#birthday').length) {
        //         if ($('.jq-dte input.valid').length === 3 && $('#nationality').val() !== '') {
        //             if ($('#genderField').length && $('#nationality').val() !== '') {
        //                 if ($('#genderField').val() !== '') {
        //                     $('form#detailsEdit button.global-btn').removeClass('is-disabled');
        //                 } else {
        //                     $('form#detailsEdit button.global-btn').addClass('is-disabled');
        //                 }
        //             } else {
        //                 $('form#detailsEdit button.global-btn').removeClass('is-disabled');
        //             }
        //         } else {
        //             $('form#detailsEdit button.global-btn').addClass('is-disabled');
        //         }
        //     } else if ($('#genderField').length) {
        //         if ($('#genderField').val() !== '' && $('#nationality').val() !== '') {
        //             if ($('#birthday').length) {
        //                 if ($('.jq-dte input.valid').length === 3 && $('#nationality').val() !== '') {
        //                     $('form#detailsEdit button.global-btn').removeClass('is-disabled');
        //                 } else {
        //                     $('form#detailsEdit button.global-btn').addClass('is-disabled');
        //                 }
        //             } else {
        //                 $('form#detailsEdit button.global-btn').removeClass('is-disabled');
        //             }
        //         } else {
        //             $('form#detailsEdit button.global-btn').addClass('is-disabled');
        //         }
        //     } else if ($('#nationality').val() !== '') {
        //         $('form#detailsEdit button.global-btn').removeClass('is-disabled');
        //     } else {
        //         $('form#detailsEdit button.global-btn').addClass('is-disabled');
        //     }
        // });

        // $(document).on('radioChange', function () {
        //     if ($('#birthday').length) {
        //         if ($('.jq-dte input.valid').length === 3 && $('#nationality').val() !== '') {
        //             if ($('#genderField').length) {
        //                 if ($('#genderField').val() !== '') {
        //                     $('form#detailsEdit button.global-btn').removeClass('is-disabled');
        //                 } else {
        //                     $('form#detailsEdit button.global-btn').addClass('is-disabled');
        //                 }
        //             } else {
        //                 $('form#detailsEdit button.global-btn').removeClass('is-disabled');
        //             }
        //         } else {
        //             $('form#detailsEdit button.global-btn').addClass('is-disabled');
        //         }
        //     } else if ($('#nationality').length) {
        //         if ($('#nationality').val() !== '' && $('#genderField').val() !== '') {
        //             if ($('#birthday').length) {
        //                 if ($('.jq-dte input.valid').length === 3) {
        //                     $('form#detailsEdit button.global-btn').removeClass('is-disabled');
        //                 } else {
        //                     $('form#detailsEdit button.global-btn').addClass('is-disabled');
        //                 }
        //             } else {
        //                 $('form#detailsEdit button.global-btn').removeClass('is-disabled');
        //             }
        //         } else {
        //             $('form#detailsEdit button.global-btn').addClass('is-disabled');
        //         }
        //     } else if ($('#genderField').val() !== '') {
        //         $('form#detailsEdit button.global-btn').removeClass('is-disabled');
        //     } else {
        //         $('form#detailsEdit button.global-btn').addClass('is-disabled');
        //     }
        // });
    }

    $('body').on('live:winnerLoaded', function (e) {
        $('.js-see-all-winner').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.winner-campagin-box').find('.additionalWinner-Box').addClass('active');
        });

        $(document).on('click', '.js-see-all-winner-desktop', function (e) {
            e.preventDefault();
            var $this = $(this);
            var $winner = $this.data('winner');
            var $pid = $this.data('pid');
            var $name = $this.data('name');
            var $date = $this.data('date');
            var $time = $this.data('time');
            var $close = $this.data('closeimg');
            // $(this).closest('.winner-campagin-box').find('.additionalWinner-Box').addClass('active');

            const generateWinnerLi = () => {
                var arrayLength = $winner.length;
                var html = '';
                for (var i = 0; i < arrayLength; i++) {
                    var $winnerItem = $winner[i];
                    html += '<li>' +
                        '<div class="winner-name"><span>Winner ' + parseInt(i + 1) + '</span>: ' + $winnerItem.winnerName + '</div>' +
                        '<div class="on-winning">on winning <b>' + $winnerItem.prizeTitle + '</b></div>' +
                        '<div class="winner-ticket">Ticket no ' + $winnerItem.winnerTicketNumber.toUpperCase() + '</div>' +
                        '</li>'
                }
                return html;
            }

            var html = ' <div class="additionalWinner-Box-new">' +
                '<div class="box">' +
                '<div class="boxHeader">' +
                '<img src="' + $close + '" class="winner-closeIcon" />' +
                '<h4>List of Winners</h4>' +
                '<h4>Campaign:' + $name + '</h4>' +
                '<h6>Announced on ' + $time + ' ' + $date + '</h6>' +
                '</div>' +
                '<div class="scrolled">' +
                '<ul>' +
                generateWinnerLi()
            '</ul>' +
                '</div>' +
                '</div>' +
                '</div>';

            $('body').append(html);

            $(".additionalWinner-Box-new").click(function () {
                $(".additionalWinner-Box-new").remove();
            });
            $(".winner-closeIcon").click(function () {
                $(".additionalWinner-Box-new").remove();
            });

            $(".additionalWinner-Box-new .box").click(function (e) {
                e.stopPropagation();
            });
        });

        $('.js-see-all-winner-close').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.winner-campagin-box').find('.additionalWinner-Box').removeClass('active');
        });
    });

    $('body').on('live:drawloaded', function (e) {
        $('.pollViewBox .close').click(function () {
            $('#pollViewBox').fadeOut();
        });

        $('.js-see-all-winner').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.draw-campagin-box').find('.additionalWinner-Box').addClass('active');
        });

        $('.js-see-all-winner-close').on('click', function (e) {
            e.preventDefault();
            $(this).closest('.draw-campagin-box').find('.additionalWinner-Box').removeClass('active');
        });

        $('.js-see-all-winner-desktop').on('click', function (e) {
            e.preventDefault();
            var $this = $(this);
            var $winner = $this.data('winner');
            var $pid = $this.data('pid');
            var $name = $this.data('name');
            var $date = $this.data('date');
            var $time = $this.data('time');
            var $close = $this.data('closeimg');
            // $(this).closest('.winner-campagin-box').find('.additionalWinner-Box').addClass('active');

            const generateWinnerLi = () => {
                var arrayLength = $winner.length;
                var html = '';
                for (var i = 0; i < arrayLength; i++) {
                    var $winnerItem = $winner[i];
                    html += '<li>' +
                        '<div class="winner-name"><span>' + resources.winner + ' ' + parseInt(i + 1) + '</span>: ' + $winnerItem.winnerName + '</div>' +
                        '<div class="on-winning">' + resources.on_winning + ' <b>' + $winnerItem.prizeTitle + '</b></div>' +
                        '<div class="winner-ticket">' + resources.ticket_no + ' ' + $winnerItem.winnerTicketNumber.toUpperCase() + '</div>' +
                        '</li>'
                }
                return html;
            }

            var html = ' <div class="additionalWinner-Box-new">' +
                '<div class="box">' +
                '<div class="boxHeader">' +
                '<img src="' + $close + '" class="winner-closeIcon" />' +
                '<h4>' + resources.list_of_winners + '</h4>' +
                '<h4>' + resources.campaign + ' ' + $name + '</h4>' +
                '<h6>' + resources.announcedOn + ' ' + $time + ' ' + $date + '</h6>' +
                '</div>' +
                '<div class="scrolled">' +
                '<ul>' +
                generateWinnerLi()
            '</ul>' +
                '</div>' +
                '</div>' +
                '</div>';

            $('body').append(html);

            $(".additionalWinner-Box-new").click(function () {
                $(".additionalWinner-Box-new").remove();
            });
            $(".winner-closeIcon").click(function () {
                $(".additionalWinner-Box-new").remove();
            });

            $(".additionalWinner-Box-new .box").click(function (e) {
                e.stopPropagation();
            });
        });
    });
});

// $(document).on("click", function (e) {
//     if (!$(e.target).is('.quick-view-dialog') && $('.quick-view-dialog').has(e.target).length === 0) {
//         if ($('.newRegisterPopup').length > 0) {
//             if ($('.newRegisterPopup').hasClass('show')) {
//                 $('.newRegisterPopup').fadeOut();
//                 $('.newRegisterPopup').removeClass('show');
//             } else {
//                 $('.newRegisterPopup').fadeIn();
//                 $('.newRegisterPopup').addClass('show');
//             }
//         }
//     }
// });