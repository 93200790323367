module.exports = {
    init: function () {
        var $customLength = $('.custom-select').length;
        var $customSelect = $('.custom-select');
        var $generatedList = $('.generated-list');
        var $flag = $('.custom-select-flag');
    
        if ($customLength) {
            $customSelect.each(function () {
                var $this = $(this);
                var $customOption = $this.find('select option');
                var $labelText = $this.parent().find('label').data('label');

                // Generate List from option
                // $this.find('select').css({'display':'none'});
                $customOption.each(function () {
                    var $brain = $(this).text();
                    var $value = $(this).val();
                    var n = $brain.indexOf('+');
                    var s = $brain.substring(0, n !== -1 ? n : $brain.length);

                    if ($this.parents('.code').length) {
                        $this.find('.generated-list').append(
                            '<li data-value=' + $value + ' data-name="' + $brain + '"><b>' + s + '</b>' + $value + '</li>'
                        );
                        if ($(this).is(':selected')) {
                            $this.find('span').text($value);
                        }
                    } else {
                        $this.find('.generated-list').append(`<li data-value=${$value.replace(/\s/g, '')}>${s}</li>`);
                        if ($(this).is(':selected')) {
                            $this.find('span').text(s);
                        }
                    }
                });

                $this.find('span').click(function () {
                    $this.find($generatedList).toggleClass('open');
                    $this.toggleClass('opened');
                    $this.parent().toggleClass('open');
                    if ($this.find($generatedList).hasClass('open')) {
                        $generatedList.slideUp('fast');
                        $this.find($generatedList).slideDown('fast');
                    } else {
                        $this.find($generatedList).slideUp('fast');
                    }

                    // return false;
                });

                $this.find($generatedList).find('li').click(function () {
                    var $selectedText = $(this).text();
                    var $selectedValue = $(this).data('value');

                    if ($this.parents('.code').length) {
                        $this.find('span').html($selectedValue);
                    } else {
                        $this.find('span').html($selectedText);
                    }

                    $this.find($generatedList).removeClass('open');
                    $this.find($generatedList).slideUp('fast');
                    $this.find('option:selected', this).removeAttr('selected');
                    // eslint-disable-next-line no-unused-expressions
                    $this.find('select option[value="' + $selectedValue.replace(/\s/g, '') + '"]').prop('selected', true).siblings;
                    $this.removeClass('opened');
                    $this.parent().removeClass('open');

                    $this.find('select').trigger('change');


                    if ($this.parents('.code').length) {
                        if ($selectedText !== 'Select topic') {
                            $this.removeClass('error');
                            $this.parent().find('label').removeClass('invalid');
                        } else {
                            $this.addClass('error');
                        }
                    } else {
                        // eslint-disable-next-line no-lonely-if
                        if ($selectedText !== 'Select topic') {
                            $this.removeClass('error');
                            $this.parent().find('label').text($labelText).removeClass('invalid');
                        } else {
                            $this.addClass('error');
                        }
                    }
                });

                if ($this.parents('.code').length) {
                    $this.find('select').change(function () {
                        $this.find('span').text($(this).children('option').filter(':selected').val());
                    });
                } else {
                    $this.find('select').change(function () {
                        $this.find('span').text($(this).children('option').filter(':selected').text());
                    });
                }

            });
        }

        $(document).on('click', function (event) {
            if (!$(event.target).closest($customSelect).length && !$(event.target).closest($flag).length) {
                $generatedList.parent().removeClass('opened');
                $generatedList.removeClass('open');
                $generatedList.closest('.input-field').removeClass('open');
                $generatedList.slideUp('fast');
            }
        });
    },
    customFlag: function () {
        var $customLength = $('.custom-select-flag').length;
        var $customSelect = $('.custom-select-flag');
        var $generatedList = $('.generated-list');

        if ($customLength) {
            $customSelect.each(function () {
                var $this = $(this);
                var $customOption = $this.find('select option');
                var $labelText = $this.parent().find('label').data('label');

                // Generate List from option
                // $this.find('select').css({'display':'none'});
                $customOption.each(function () {
                    var $brain = $(this).text();
                    var $value = $(this).val();
                    var $selected = $(this).attr('selected')
                    var n = $brain.indexOf('+');
                    var s = $brain.substring(0, n !== -1 ? n : $brain.length);
                    var $two = $value.slice(0, $value.length - 1);

                    if (!$selected) {
                        $this.find('.generated-list').append(
                            '<li data-value=' + $value + '><i class="flag-sm flag-sm-' + $two + '"></i>' + s + '</li>'
                        );
                    }
                    if ($(this).is(':selected')) {
                        $this.find('span').html('<i class="flag-sm flag-sm-' + $two + '"></i>' + s);
                    }

                });

                $this.find('span').click(function () {
                    $this.find($generatedList).toggleClass('open');
                    $this.toggleClass('opened');
                    $this.parent().toggleClass('open');


                    if ($this.find($generatedList).hasClass('open')) {
                        $generatedList.slideUp('fast');
                        $this.find($generatedList).slideDown('fast');

                    } else {
                        $this.find($generatedList).slideUp('fast');
                    }
                });

                $this.find($generatedList).find('li').click(function () {
                    var $selectedText = $(this).text();
                    var $selectedValue = $(this).data('value');

                    if ($this.parents('.code').length) {
                        $this.find('span').html($selectedValue);
                    } else {
                        $this.find('span').html($selectedText);
                    }

                    $this.find($generatedList).removeClass('open');
                    $this.find($generatedList).slideUp('fast');
                    $this.find('option:selected', this).removeAttr('selected');
                    // eslint-disable-next-line no-unused-expressions
                    $this.find('select option[value="' + $selectedValue + '"]').prop('selected', true).siblings;
                    $this.removeClass('opened');
                    $this.parent().removeClass('open');

                    $this.find('select').trigger('change');



                    // eslint-disable-next-line no-lonely-if

                });


                $this.find('select').change(function () {
                    var $code = $(this).children('option').filter(':selected').val();
                    var $codes = $code.slice(0, $code.length - 1);
                    $codes = "<i class='flag-sm flag-sm-" + $codes + "'></i>";


                    $this.find('span').html($codes + $code)
                });
            });
        }

    },
    // reInit: function() {
    //     $(document).on('shipping:newAddress', function() {
    //         module.exports.init();
            
    //     })
    // }
};
